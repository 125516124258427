export enum ChatRoutes {
  BasePrefix = "chats",
  SendQuestion = "questions/send",
  RawPrompt = "questions/raw",
  LikeAnswer = "answer/:messageId/like",
  DislikeAnswer = "answer/:messageId/dislike",
  SendDislikeDescription = "answer/:messageId/:sessionId/dislikeDescription",
  SendForm = "answer/:promptTemplateId/form",
  SubscribeOnHistory = "historySubscribe",
  UnsubscribeFromHistory = "historyUnsubscribe",
  GetHistory = "history"
}

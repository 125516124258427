import { useEffect } from 'react';
import { useLogin, useRecoveryUser } from '../api/authApi';
import { EEventName } from '../events/events';
import { setGreeting, setInitialFetching, setIsLogin, setSessionId, resetAuthStore, setAuthError, setIsAuthSetting } from '../redux/reducers/authSlice';
import { store } from '../redux/store';
import { ILoginResponseDTO } from '../submodules/common/interfaces/dto/auth/ilogin-response.interfaces';
import { IApiResponseGenericDTO } from '../submodules/common/interfaces/dto/common/iapi-response.interface';
import { getLocalStorageService, setLocalStorageService } from './localStorageService';
import { prepareErrorMessage } from '../utils/responseUtils';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { ELocalStorage, EToken } from '../types/localStorageTypes';
import { subscribeChatHistory, useChatHistory } from '../api/chatApi';
import { prepareChatHistoryMessages } from '../utils/messageUtils';
import { socketHistory } from '../api/utils/apiSocketUtils';
import { getStorage } from '../utils/localStorageUtil';
import { broadcastChannelServicePost } from '../events/broadcastChannel';
import { useActions } from '../hooks/useActions';
import { useNetwork } from '../hooks/useTypeSelectorHooks/useNetwork';
import useNavigatorOnline from '../hooks/useNavigatorOnline';
// import { getCookie } from '../utils/cookiesUtils';
// import { appConfig } from '../infrastructure/app-config.infrastructure';

const setAuthData = (data: ILoginResponseDTO) => {
  const dispatch = store.dispatch;

  dispatch(setSessionId(data?.sessionId ?? ''));
  dispatch(setGreeting(data?.greeting ?? ''));
  // dispatch(setProposedTopics(data?.proposedTopics ?? []));
  dispatch(setIsLogin(true));
};

export const onSuccessRecoveryUser = async ({ data }: IApiResponseGenericDTO<ILoginResponseDTO>) => {
  setAuthData(data);
  await subscribeChatHistory(getStorage(EToken.TOKENS)?.[ELocalStorage.AccessToken] ?? '');
};

export const onSuccessLogin = async ({ data }: IApiResponseGenericDTO<ILoginResponseDTO>) => {
  const dispatch = store.dispatch;
  setAuthData(data);

  await subscribeChatHistory(data.access.accessToken);

  setLocalStorageService(data.access.accessToken, data.access.refreshToken);

  broadcastChannelServicePost({ type: EEventName.LOGIN });

  dispatch(setIsAuthSetting(true));
  dispatch(setInitialFetching(true));
};

export const onError = (data: IApiResponseGenericDTO<ILoginResponseDTO>) => {
  const dispatch = store.dispatch;
  dispatch(resetAuthStore());

  const messageData = prepareErrorMessage(data.statusCode, data.error?.errorCode);
  dispatch(setAuthError({ messageData }));
  dispatch(setIsLogin(false));
  dispatch(setInitialFetching(true));
};

export const localStorageService = async () => {
  await new Promise((resolve) => {
    socketHistory.emit('historySubscribe',
      { accessToken: getStorage(EToken.TOKENS)?.[ELocalStorage.AccessToken] },
      (data) => {
        resolve(data);
      });
  });
};

export const useAuthServiceSetting = () => {
  const { disconnect } = useNetwork();
  const isOnline = useNavigatorOnline();
  const { setInitialFetching, setMessagesHistory, resetAuthStore } = useActions();
  const { search } = useLocation();
  const sourceUrl = queryString.parse(search)?.sourceUrl;
  const location = Array.isArray(sourceUrl) ? (sourceUrl?.[0] ?? '') : sourceUrl ?? '';
  // const visitorId = getCookie(appConfig.AI_VISITOR_ID);

  const dispatch = store.dispatch;
  const isNetwork = !disconnect && isOnline;

  const { isError: isErrorRecoveryUser, isSuccess } =
    useRecoveryUser({
      onSuccess: (data) => onSuccessRecoveryUser(data),
      onError,
      enabled: isNetwork && !store.getState().auth.isAuthSetting &&
        !!getLocalStorageService(EToken.TOKENS),
    });

  useChatHistory({
    onSuccess: ({ data }) => {
      setMessagesHistory(prepareChatHistoryMessages(data ?? []));
      dispatch(setInitialFetching(true));
      dispatch(setIsAuthSetting(true));
    },
    onError,
    enabled: isNetwork && !store.getState().auth.isAuthSetting &&
      !isErrorRecoveryUser && isSuccess && !!getLocalStorageService(EToken.TOKENS),
  });

  useLogin({
    onSuccess: (data) => onSuccessLogin(data),
    onError,
    enabled: isNetwork && !store.getState().auth.isAuthSetting && !Boolean(getLocalStorageService(EToken.TOKENS)),
    data: { location },
  });

  useEffect(() => {
    resetAuthStore();
  }, [disconnect, isOnline]);
};

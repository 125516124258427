import React, { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import './assets/scss/index.scss';

// const _ChatPage = React.lazy(() => import('./pages/_Chat'));
const ChatPage = React.lazy(() => import('./pages/Chat'));
const VersionPage = React.lazy(() => import('./pages/Version'));

import './i18n/config';
import { Loader } from './components/Loader';
import { pageRoutes } from './routes/pageRoutes';
import { useAuth } from './hooks/useTypeSelectorHooks/useAuth';
import { useAuthServiceSetting } from './services/authService';
import { broadcastChannelServices } from './events/broadcastChannel';

export const broadcastChannel = new BroadcastChannel('auth');

// ---

function WrapperChat() {
  const { initialFetching } = useAuth();

  useAuthServiceSetting();
  const isShowChatPage = initialFetching;

  return (
    <>
      {isShowChatPage ? <ChatPage /> : <Loader showTitle={false} />}
    </>
  );
}

// ---

function App() {
  useEffect(() => {
    broadcastChannelServices();
    return () => {
      broadcastChannel.close();
    };
  }, []);

  return (
    <div className='content'>
      <Routes>
        <Route path={pageRoutes.chat} element={<React.Suspense fallback={null}>
          <WrapperChat />
        </React.Suspense>} />
        <Route path={pageRoutes.version} element={<React.Suspense fallback={null}>
          <VersionPage />
        </React.Suspense>} />
        <Route path='*' element={<Navigate replace to={pageRoutes.notFound} />} />
      </Routes>
    </div>
  );
}

export default App;

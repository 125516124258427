import { useQuery } from '@tanstack/react-query';
import { apiRoutes } from '../routes/apiRoutes';
import { IApiResponseGenericDTO } from '../submodules/common/interfaces/dto/common/iapi-response.interface';
import { ILoginResponseDTO } from '../submodules/common/interfaces/dto/auth/ilogin-response.interfaces';
import { EQueryKey } from './types/queryTypes';
import { getHeaders, getHeadersAuthBeanParams, request } from './utils/apiUtils';
import { EMethod } from './types/apiTypes';
// import { encodeQueryData, removeNull } from '../utils/cookiesUtils';

interface IUseLoginParams {
  onSuccess: (data: IApiResponseGenericDTO<ILoginResponseDTO>) => void,
  onError: (data: IApiResponseGenericDTO<ILoginResponseDTO>) => void,
  enabled?: boolean,
  data: {
    location?: string,
    visitorId?: string,
  }
}

export const useLogin = ({ onSuccess, onError, enabled = true, data }: IUseLoginParams) => {
  const context = useQuery<IApiResponseGenericDTO<ILoginResponseDTO>, IApiResponseGenericDTO<ILoginResponseDTO>>({
    enabled,
    queryKey: [EQueryKey.AUTH],
    queryFn: async () => await request({
      url: !!data.location ? `${apiRoutes.login}?location=${data.location}` : apiRoutes.login,
      method: EMethod.POST,
      headers: () => getHeaders(),
      data,
    }),
    onSuccess,
    onError,
    retryDelay: 1000,
    retry: 2,
  });
  return context;
};

export const useRefreshToken = () => (
  useQuery<IApiResponseGenericDTO<ILoginResponseDTO>, Error>({
    queryKey: [EQueryKey.REFRESH],
    queryFn:
      async () => await request({ url: apiRoutes.refreshToken, method: EMethod.POST, headers: () => getHeaders() }),
  })
);

export const useRecoveryUser = ({ onSuccess, onError, enabled = true }) => {
  const context = useQuery<IApiResponseGenericDTO<ILoginResponseDTO>, IApiResponseGenericDTO<ILoginResponseDTO>>({
    enabled,
    queryKey: [EQueryKey.RECOVERY_USER],
    queryFn: async () => await request({
      url: apiRoutes.recoveryUser,
      method: EMethod.GET,
      headers: () => getHeaders(getHeadersAuthBeanParams()),
    }),
    onSuccess,
    onError,
    retryDelay: 1000,
    retry: 2,
  });

  return context;
};
